import { Injectable } from '@angular/core';
import { FireStorageService, HAvatar, LocalStorageService } from '@hss-m/uikit-common';
import {
  Auth, GoogleAuthProvider, signOut, authState, User,
  sendSignInLinkToEmail, signInWithEmailLink, isSignInWithEmailLink,
  reload, updatePassword, confirmPasswordReset
} from '@angular/fire/auth';
import { signInWithPopup, signInWithEmailAndPassword } from '@firebase/auth';
import { HttpService } from '@hss-m/uikit-common';
import { BehaviorSubject, Subscription, interval, lastValueFrom, map } from 'rxjs';
import { LoggerService } from 'src/app/service/logger.service';
import { StringUtil } from '@hss-m/uikit-common';
import { LOCAL_STORAGE } from 'src/app/app.module';
import { IUser } from './h-auth-model';

export enum AccessCode {
  A = 'A',
  C = 'C',
  D = 'D',
  R = 'R',
  U = 'U'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private firebbaseUser$: Promise<User>;
  private authStateRef: Subscription;
  private bearerToken = '';
  private claims: any;
  me$: Promise<IUser>;
  collectionName = "users";
  onProfileUpdate = new BehaviorSubject(false);
  autoRefreshSubs: Subscription;
  constructor(
    private lsc: LocalStorageService,
    private auth: Auth,
    private httpService: HttpService,
    private logger: LoggerService,
    private storageService: FireStorageService
  ) { }

  loginWithPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  sendSignInLinkToEmail(email: string) {
    const actionCodeSettings = {
      url: this.generateEmailLink(email, 'signup-by-email-link'),
      handleCodeInApp: true
    };
    return sendSignInLinkToEmail(this.auth, email, actionCodeSettings);
  }

  sendInvitation(user) {
    return lastValueFrom(this.httpService.post(`invitations`, user));
  }

  sendPasswordResetEmail(email) {
    return this.httpService.post(`public/forgot-password`, { email });
  }

  acceptInvitation(payload) {
    return this.httpService.post(`public/invitations/accept`, payload);
  }

  changePassword(payload) {
    return this.httpService.post(`public/change-password`, payload);
  }

  generateEmailLink(email, url) {
    return `${location.origin}/auth/${url}?email=${email}&t=${Date.now()}`;
  }

  signInWithEmailLink(email: string) {
    if (isSignInWithEmailLink(this.auth, location.href)) {
      return signInWithEmailLink(this.auth, email, location.href);
    } else {
      return Promise.reject('Invalid Link');
    }
  }

  confirmPasswordReset(oobCode: string, newPassword: string) {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  updatePassword(newPassword: string) {
    return updatePassword(this.me(), newPassword);
  }

  async loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  me(): User {
    return this.auth?.currentUser;
  }

  async getLoggedInUser(forceReload = false): Promise<User> {
    if (forceReload) {
      await reload(this.auth.currentUser);
      this.authStateRef?.unsubscribe();
      this.firebbaseUser$ = null;
    }
    if (!this.firebbaseUser$) {
      this.firebbaseUser$ = new Promise((resolve, reject) => {
        this.authStateRef = authState(this.auth).subscribe({
          next: (res) => {
            this.onProfileUpdate.next(true);
            if (res) {
              resolve(res);
            } else {
              reject();
            }
          },
          error: reject
        })
      });
    }
    return this.firebbaseUser$;
  }

  setAutoRefreshAuthToken() {
    // Refresh Auth token every 10(600000 - millis) minutes
    this.autoRefreshSubs = interval(600000).subscribe(() => {
      this.getClaims(true);
    });
  }

  isLoggedIn(): Promise<boolean> {
    return this.getLoggedInUser().then(user => {
      if (!this.autoRefreshSubs) {
        this.setAutoRefreshAuthToken();
      }
      return this.getClaims().then(claims => {
        this.claims = claims;
        return user && this.claims?.roles?.length ? true : false;
      })
    }).catch(err => {
      this.me$ = null;
      return false;
    });
  }

  async logout(redirect = true) {
    await signOut(this.auth);
    this.resetBearerToken();
    const keyValueToKeep: any = localStorage.getItem('terra_tables_config');
    this.lsc.clear();
    sessionStorage.clear()
    localStorage.setItem('terra_tables_config', keyValueToKeep);
    this.autoRefreshSubs?.unsubscribe();
    if (redirect) {
      window.location.replace('auth/signin');
    }
  }

  async getClaims(forceRefresh = false) {
    const user = await this.getLoggedInUser(forceRefresh);
    this.setBearerToken(user['accessToken']);
    if (!this.claims) {
      return user.getIdTokenResult(forceRefresh).then(res => {
        if (!res.claims?.roles) {
          return new Promise((resolve) => {
            setTimeout(() => {
              this.getClaims(true).then(claims => {
                resolve(claims);
              });
            }, 1500);
          });
        }
        this.logger.debug("User claims", res.claims);
        return res.claims;
      })
    } else {
      return Promise.resolve(this.claims);
    };
  }

  async hasAnyAccess(roles: string[]) {
    this.claims = await this.getClaims();
    const userRoles = this.claims?.roles || [];
    this.logger.debug("Roles assigned", userRoles);
    const exist = roles.find(role => {
      return userRoles.includes(role);
    });
    return exist ? true : false;
  }

  async hasRouteAccess(featureCode: string, accessCode?) {
    this.claims = await this.getClaims();
    return this.hasRouteAccessSync(featureCode, accessCode);
  }

  hasRouteAccessSync(featureCode: string, accessCode: AccessCode = AccessCode.R) {
    const permissions = this.claims?.permissions || {};
    return permissions[`${featureCode}_${accessCode}`] === 1;
  }

  setBearerToken(token: string) {
    this.bearerToken = token;
    this.lsc.set(LOCAL_STORAGE.AUTH_TOKEN, token);
  }

  getBearerToken(): string {
    return this.bearerToken || this.lsc.get(LOCAL_STORAGE.AUTH_TOKEN);
  }

  resetBearerToken(): void {
    this.lsc.remove(LOCAL_STORAGE.AUTH_TOKEN);
  }

  async getUserAvatar() {
    const avatar: HAvatar = {};
    if(!this.me()) {
      return avatar;
    }
    const user = await this.fetchProfileDetails();
    if (!user) {
      return avatar;
    }
    if (user['photoUrl']) {
      avatar.url = await this.storageService.getViewUrl(user['photoUrl']);
    } else {
      avatar.label = StringUtil.avatarLabel(user['displayName']);
    }
    return avatar;
  }

  updateProfile(profile: any) {
    return this.httpService.patch('users/profile', profile).pipe(map(res => {
      this.getLoggedInUser(true);
      return res;
    }));
  }

  fetchProfileDetails(reload = false): Promise<IUser> {
    if(reload) { this.me$ = null; }
    if(!this.me$) {
      this.me$ = lastValueFrom(this.httpService.get('users/profile')).then((res: any) => res.data);
    }
    return this.me$;
  }

  isAdmin() {
    return this.fetchProfileDetails().then((user: IUser) => {
      const admin = user.roles?.find(role => role.code === 'ADMIN');
      return admin ? true : false;
    })
  }

  sendLoggedinEvent() {
    return lastValueFrom(this.httpService.post('events/login', {}));
  }

}
